import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

// components
import Routes from "./router/routes";

//elements
import Preloader from "./shared/Preloader";

// hoc
import withRootSettings from "./hoc/withRootSettings";

//helpers
import { updateEmployee } from "./libs/User";
import { useGetUserDealerships } from "./swr/userManagement";
import useProfile from "./hooks/useProfile";


function App() {
  const {
    data: currentUser,
    mutate,
  } = useProfile({ config: { revalidateOnMount: true } });
  const { data } = useGetUserDealerships({ config: { enabled: !!currentUser?.id } });

  useEffect(() => {
    if (currentUser.id && !currentUser.isVerified) {
      updateEmployee(
        { data: { userId: currentUser.localAccountId, status: true } },
        "userAccountStatus"
      ).then(() => mutate());
    }
  }, [JSON.stringify(currentUser)]);


  if (currentUser?.id && data?.length && currentUser.activeDealership.id) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="app-loading">
      <Preloader size="large" />
    </div>
  );
}

export default withRootSettings(App);
