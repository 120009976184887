import API from "./Api";

// helpers
import { pathToUrl } from "../swr/pathToUrl";
import { buildQueryString } from "../helpers/api";
import routes from "../swr/router.constants";

export default {
  postNotifications: async ({ userId, dealershipId, ...params }) => {
    const queryParams = buildQueryString(params);

    const url = pathToUrl(
      routes.notifications.replace("/api", ""),
      { userId, dealershipId }
    );

    return API.post(`${url}${queryParams}`);
  },

  getSystemNotifications: async ({ userId, dealershipId }) => {
    const url = pathToUrl(
      routes.ActiveSystemNotifications.replace("/api", ""),
      { userId, dealershipId }
    );

    return API.get(url);
  },

  getNotifications: async ({ userId, dealershipId }, type) => {
    const url = `User/${userId}/Dealerships/${dealershipId}/${type}`;
    const result = await API.get(url);

    return result.data;
  }
};
