import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// helpers
import { getChildrenByDisplayName, getOtherChildren } from "../../helpers/subComponentsUtils";

// assets
import logo from "../../assets/icons/companyLogo.png";
import "../../styles/_header.scss";
import "../../styles/components/Header.scss";


const HeaderProfile = ({ children }) => children;
HeaderProfile.displayName = "HeaderProfile";

export const BaseHeader = ({ children }) => {
  const headerRef = useRef(null);

  const profile = getChildrenByDisplayName(children, HeaderProfile.displayName);
  const otherChildren = getOtherChildren(children, [HeaderProfile.displayName]);

  // effects
  useEffect(() => {
    const cachedRef = headerRef.current;

    const observer = new IntersectionObserver(
      ([e]) => cachedRef?.classList.toggle("time-header_is-sticky", e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(cachedRef);

    // unmount
    return () => observer.unobserve(cachedRef);
  }, [headerRef]);

  // renders
  return (
    <header className="time-header" ref={headerRef}>
      {/* { contextHolder } */}
      <div className="time-header__content">
        <Link
          to="/dashboard/service"
          className="time-header__logo"
          data-testid="logo"
        >
          <img src={logo} alt="logo" className="time-header__logo-icon" data-testid="logo-icon" />
        </Link>
        { profile && <div className="time-header__account" data-testid="profile">{profile}</div> }
      </div>
      { otherChildren }
    </header>
  );
};

BaseHeader.propTypes = {
  children: PropTypes.node
};

BaseHeader.Profile = HeaderProfile;

export default BaseHeader;
