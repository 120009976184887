import { useLocation } from "react-router-dom";

// components
import DefaultHeader from "./Header";
import BaseHeader from "./BaseHeader";

function Header() {
  const location = useLocation();

  return location.pathname.includes("/onboarding") ? <BaseHeader /> : <DefaultHeader />;
}

export default Header;
