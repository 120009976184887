import useSWR from "swr";

// helpers
import routes from "../swr/router.constants";
import { buildUrl } from "../swr/pathToUrl";
import { DASHBOARD_JOB_TITLE_PERMISSIONS } from "../constants/permissions";
import { fetcher, useActiveDealership } from "../swr/userManagement";
import msalSerializer, { setUserData } from "../helpers/msalSerializer";
import { setAnalyticProperties } from "../helpers/analytics";
// import { setAnalyticProperties } from "../helpers/analytics";

const getPermissions = (profileData) => {
  const permissions = {
    dashboard: {
      service: DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardService.includes(profileData.jobTitle),
      parts: DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardParts.includes(profileData.jobTitle),
      "op-codes": true,
      reports: true,
      "labor-matrix": DASHBOARD_JOB_TITLE_PERMISSIONS.laborMatrix.includes(profileData.jobTitle),
      "labor-types": true,
    },
    isAdmin: ["ADMIN", "ADMIN_SUPER"].includes(profileData.role),
    isSummaryView: ["ADMIN", "ADMIN_SUPER", "MANAGER", "MANAGER_SUPER"].includes(profileData.role),
  };
  
  if (DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardService.includes(profileData.jobTitle)) {
    permissions.dashboard.service = true;
  }

  if (profileData.devFeatures) {
    permissions.dashboard.service = true;
    permissions.dashboard.parts = true;
    permissions.dashboard["op-codes"] = true;
    permissions.dashboard["labor-matrix"] = true;
  }

  return permissions;
};

const fetchUserData = async (url) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const key = buildUrl(url, { userId: user?.localAccountId });

  return fetcher(key).then(response => {
    const serializedData = msalSerializer(response.value[0]);
    const permissions = getPermissions(serializedData);
  
    setAnalyticProperties(serializedData);
    setUserData(serializedData);
  
    return {
      userData: serializedData,
      permissions,
    };
  });
};

const DEFAULT_PERMISSIONS = getPermissions({});

function useProfile(args = {}) {
  const { config = {} } = args;

  const { data, ...rest } = useSWR(routes.userManagementUrls.getUser, fetchUserData, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false,
    fallbackData: {
      userData: {},
      permissions: DEFAULT_PERMISSIONS,
    },
    ...config,
  });

  const { data: activeDealership } = useActiveDealership();

  return {
    data: {
      ...data.userData,
      permissions: data.permissions,
      activeDealership,
    },
    ...rest,
  };
}

export default useProfile;
