import useSWR, { useSWRConfig } from "swr";

// helpers
import { buildUrl } from "./pathToUrl";
import routes from "./router.constants";
import axios, { authApiConfig } from "../libs/Api";
import msalSerializer from "../helpers/msalSerializer";
import { setAnalyticProperties } from "../helpers/analytics";
import { setDealer, getDealer } from "../helpers/permissions";


export const fetcher = async (url) => {
  const response = await axios.get(url, authApiConfig);

  return response.data;
};

export const useGetUserDealerships = (args = {}) => {
  const { params, config = { enabled: true } } = args;

  const user = JSON.parse(localStorage.getItem("user"));
  const fetchKey = buildUrl(routes.userManagementUrls.userDealerships, { userId: user?.localAccountId, ...params });

  const { mutate } = useSWRConfig();

  const {
    data,
    error,
    ...rest
  } = useSWR(user?.localAccountId && config.enabled ? fetchKey : null, url => fetcher(url).then(response => {
    let dealershipsOptions = response.map(item => ({
      id: item.dealershipId,
      name: item.dealershipName,
    }));
    const defaultDealership = dealershipsOptions.find(item => item.id === user.defaultDealershipId);
    
    if (!getDealer().id && defaultDealership) {
      defaultDealership.disabled = true;
      setDealer(defaultDealership);

      mutate("activeDealership");
    } else {
      const activeDealer = getDealer();
      activeDealer.disabled = true;
      dealershipsOptions = [
        activeDealer,
        ...dealershipsOptions.filter(item => item.id !== activeDealer.id),
      ];
    }

    return dealershipsOptions;
  }), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...config,
  });

  return {
    isLoading: !error && !data,
    data: data || [],
    error,
    ...rest,
  };
};

export const useGetUser = (args = {}) => {
  const { params, config } = args;
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchKey = buildUrl(routes.userManagementUrls.getUser, { userId: user?.localAccountId, ...params });

  const { data, error, ...rest } = useSWR(user?.localAccountId ? fetchKey : null, (url) => {
    return fetcher(url).then(response => {
      const userResp = msalSerializer(response.value[0]);
    
      setAnalyticProperties(userResp);
      localStorage.setItem("user", JSON.stringify(userResp));

      return userResp;
    });
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...config,
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    ...rest,
  };
};

export const useActiveDealership = () => {
  const { data, mutate } = useSWR("activeDealership", () => new Promise(resolve => resolve(getDealer())));

  return {
    data: data || getDealer(),
    mutate: (dealership) => {
      setDealer(dealership);
      mutate();
    },
  };
};
