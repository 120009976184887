/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";
import { notification } from "antd";
import dayjs from "dayjs";

// helpers
import useReportingDate from "../hooks/useReportingDate";
import notificationsApi from "../libs/notifications";
import useProfile from "../hooks/useProfile";

const MINUTE = 60000;
const MAP_NOTIFICATION_TYPE = {
  "info": "info",
  "warning": "warning",
};

const showNotifications = (notifications, api) => {
  const closedSystemNotifications = JSON.parse(localStorage.getItem("closedSystemNotifications") || "[]");

  notifications.sort(prev => prev.displayType === "ALERT" ? -1 : 1);
  notifications.filter(item => !closedSystemNotifications.includes(item.notificationId)).forEach(item => {
    const type = MAP_NOTIFICATION_TYPE[item.displayType?.toLowerCase()] || "info";

    api[type]({
      key: item.notificationId,
      message: item.link ? <a target="_blank" rel="noreferrer" href={item.link}>{ item.notificationText }</a> : item.notificationText,
      duration: 0,
      closeIcon: <IoClose />,
      // eslint-disable-next-line no-undefined
      icon: type === "warning" ? <IoIosWarning /> : undefined,
      className: `time-system-notification time-system-notification_type-${type}`,
      onClose: () => {
        api.destroy(item.notificationId);

        if (item.notificationId !== "initial-date-selection") {
          const closedNotifications = JSON.parse(localStorage.getItem("closedSystemNotifications") || "[]");
          closedNotifications.push(item.notificationId);
          localStorage.setItem("closedSystemNotifications", JSON.stringify(closedNotifications));
        }
      },
    });
  });
};

export const withSystemNotifications = (Component) => {
  return props => {
    const [hasNewNotifications, setHasNewNotifications] = useState(false);

    const notificationIntervalRef = useRef(null);

    const { data: { id, activeDealership } } = useProfile();
    const [[startDateReporting, endDateReporting]] = useReportingDate();

    const [api, contextHolder] = notification.useNotification();

    // methods
    const checkUserNotifications = () => {
      notificationsApi.getNotifications({
        userId: id,
        dealershipId: activeDealership.id
      }, "unreadnotifications")
        .then(resp => setHasNewNotifications(resp > 0))
        .catch(() => {
          clearInterval(notificationIntervalRef.current);
          setHasNewNotifications(false);
        });
    };
  
    const handleNotificationInterval = () => {
      return (notificationIntervalRef.current = setInterval(() => {
        checkUserNotifications();
      }, MINUTE * 5));
    };
  
    // effects
    useEffect(() => {
      checkUserNotifications();
  
      process.env.NODE_ENV !== "development" && handleNotificationInterval();
  
      return () => clearInterval(notificationIntervalRef.current);
    }, [location.pathname, activeDealership.id]);
  
    useEffect(() => {
      notificationsApi.getSystemNotifications({ userId: id, dealershipId: activeDealership.id })
        .then(({ data: resp }) => showNotifications(resp, api));
    }, [activeDealership.id]);

    useEffect(() => {
      if (startDateReporting && endDateReporting) {
        showNotifications([{
          notificationId: "initial-date-selection",
          notificationText: `${dayjs(startDateReporting).format("MMM YYYY")} has been selected as the initial date selection on all date selectors for the dashboard.`
        }], api);
      }
    }, [startDateReporting, endDateReporting, location.pathname]);

    return (
      <>
        { contextHolder }
        <Component {...props} hasNewNotifications={hasNewNotifications}/>
      </>
    );
  };
};
