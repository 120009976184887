import axios from "../libs/Api";
import Cache from "../helpers/Cache";

export const SWR_CONFIG = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
  shouldRetryOnError: false,
  provider: () => new Cache(),
  fetcher: (url) => axios.get(url, { baseURL: process.env.REACT_APP_API_URL }).then((resp) => resp.data),
};
