import React from "react";

const IsNotification = (props) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <desc>Created with sketchtool.</desc>
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/notifications">
          <path
            d="M18.6666667,16.1666667 L19.5,16.1666667 C19.9602373,16.1666667 20.3333333,16.5397627 20.3333333,17 C20.3333333,17.4602373 19.9602373,17.8333333 19.5,17.8333333 L4.5,17.8333333 C4.03976271,17.8333333 3.66666667,17.4602373 3.66666667,17 C3.66666667,16.5397627 4.03976271,16.1666667 4.5,16.1666667 L5.33333333,16.1666667 L5.33333333,16.1666667 L5.33333333,10.3333333 C5.33333333,6.651435 8.31810167,3.66666667 12,3.66666667 C15.6818983,3.66666667 18.6666667,6.651435 18.6666667,10.3333333 L18.6666667,16.1666667 Z M17,16.1666667 L17,10.3333333 C17,7.57190958 14.7614237,5.33333333 12,5.33333333 C9.23857625,5.33333333 7,7.57190958 7,10.3333333 L7,16.1666667 L17,16.1666667 Z M10.3333333,19.5 L13.6666667,19.5 C14.126904,19.5 14.5,19.873096 14.5,20.3333333 C14.5,20.7935706 14.126904,21.1666667 13.6666667,21.1666667 L10.3333333,21.1666667 C9.87309604,21.1666667 9.5,20.7935706 9.5,20.3333333 C9.5,19.873096 9.87309604,19.5 10.3333333,19.5 Z"
            id="Shape"
            fillOpacity="0.5"
            fill="#FFFFFF"
          ></path>
          <circle id="Oval" fill="#032B75" cx="17.5" cy="6.5" r="6"></circle>
          <circle id="Oval-Copy" fill="#F15B40" cx="17.5" cy="6.5" r="3.5"></circle>
        </g>
      </g>
    </svg>
  );
};

export default IsNotification;
