/* eslint-disable no-undefined */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */

import React from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import cn from "classnames";
import { Tooltip } from "antd";
import { FiEdit3, FiLogOut } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import {
  RiUserSettingsLine,
  RiQuestionLine,
  RiNotification3Line
} from "react-icons/ri";
import { IoMdCopy, IoMdCheckboxOutline } from "react-icons/io";
import BaseHeader from "./BaseHeader";

// components
import Select from "../../shared/Select";

// hocs
import { withSystemNotifications } from "../../hoc/withSystemNotifications";

// helpers
import { useGetUserDealerships, useActiveDealership } from "../../swr/userManagement";
import useProfile from "../../hooks/useProfile";
import { userHasAccessDepartment, setDashboard } from "../../helpers/permissions";

// constants
import {
  departmentPermissionsDashboard,
  departmentPermissionsAiStatements
} from "../../constants/permissions";

// assets
import defaultAvatar from "../../assets/icons/avatar.svg";
import IsNotification from "../../assets/IconsComponents/IsNotification";

const { dashboardService, dashboardParts } = departmentPermissionsDashboard;
const { serviceExpenses, serviceSales, partsExpenses, partsSales } = departmentPermissionsAiStatements;

export const MAIN_ROUTES = [
  {
    to: "/dashboard/",
    icon: <MdDashboard />,
    text: "AI Dashboard",
    dataTestId: "dashboard",
    roles: ["*"],
    jobTitles: [...dashboardService, ...dashboardParts]
  },
  {
    to: "/ai-statement/",
    icon: <IoMdCopy />,
    text: "AI Statement",
    dataTestId: "ai-statement",
    roles: ["*"],
    jobTitles: [...serviceExpenses, ...serviceSales, ...partsExpenses, ...partsSales]
  },
  {
    to: "/ai-reviews",
    icon: <IoMdCheckboxOutline />,
    text: "AI Reviews",
    roles: ["DEVELOPER"],
    jobTitles: []
  },
];

export const MAIN_ACTIONS = [
  {
    to: "/administration",
    title: "Administration",
    icon: <RiUserSettingsLine />,
    dataTestId: "administration"
  },
  {
    to: "/support",
    title: "Support",
    icon: <RiQuestionLine />,
    dataTestId: "support"
  },
  {
    to: "/notifications",
    title: "Notifications",
    icon: (hasNewNotifications) => hasNewNotifications ? <IsNotification data-testid="is-notification" /> : <RiNotification3Line data-testid="notification-icon" />,
    dataTestId: "notifications"
  }
];

const Header = ({ hasNewNotifications }) => {
  const { instance } = useMsal();
  const { data: profileData } = useProfile();
  const { data: activeDealership, mutate } = useActiveDealership();

  const location = useLocation();
  const history = useHistory();
  
  const {
    data: userDealerships,
    isLoading,
  } = useGetUserDealerships();

  const isDashboardOpCodes = location.pathname.includes("/dashboard/op-codes");
  const isAdmin = userHasAccessDepartment(["ADMIN", "ADMIN_SUPER"]);

  const selectOptions = userDealerships.map((dealership) => ({
    label: dealership.name,
    value: dealership.id,
    disabled: dealership.disabled
  }));

  const handleChangeActiveDealership = (dealershipName) => {
    const selectedDealership = userDealerships.find((dealership) => dealership.name === dealershipName);

    mutate(selectedDealership);
    
    if (isDashboardOpCodes) {
      history.replace(location.pathname);
    }
  };

  const logOutHandler = () => {
    localStorage.clear();
    setDashboard("service");
    try {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
      });
    } catch {
      return null;
    }
  };

  // render
  return (
    <BaseHeader>
      <BaseHeader.Profile>
        <Link to="/profile-settings" className="time-header__account--avatar" data-testid="profile-settings">
          <img
            src={profileData?.avatar ?? defaultAvatar}
            alt="avatar"
            className="time-header__account--avatar-icon"
            data-testid="avatar"
          />
          <FiEdit3 className="time-header__account--avatar-edit" data-testid="avatar-edit" />
        </Link>
        <div className="time-header__account--fullname" data-testid="fullname">{profileData?.fullName}</div>
      </BaseHeader.Profile>
      <nav className="time-header__navbar">
        {MAIN_ROUTES.map((route) => {
          const isAccess = userHasAccessDepartment(route.roles, route.jobTitles);

          return isAccess ? (
            <NavLink key={route.to} to={route.to} className="time-header__navbar--item" data-testid={route.dataTestId}>
              {route.icon}
              {route.text}
            </NavLink>
          ) : null;
        })}
      </nav>
      <div className="time-header__content">
        <Select
          onChange={e => handleChangeActiveDealership(e.label)}
          value={selectOptions.find((option) => option.value === activeDealership.id)}
          options={selectOptions}
          type="secondary"
          isLoading={isLoading}
          aria-label="dealership-select"
        />
        <div className="time-header__helper-bar">
          {
            MAIN_ACTIONS.filter(action => action.to.includes("administration") ? isAdmin : true).map(actions => (
              <Tooltip title={actions.title} key={actions.dataTestId}>
                <NavLink to={actions.to} className="time-header__helper-bar--item" data-testid={actions.dataTestId}>
                  {typeof actions.icon === "function" ? actions.icon(hasNewNotifications) : actions.icon}
                </NavLink>
              </Tooltip>
            ))
          }
          <Tooltip title="Log Out">
            <div
              className={cn("time-header__helper-bar--item", "log-out")}
              onClick={logOutHandler}
              data-testid="log-out"
            >
              <FiLogOut />
            </div>
          </Tooltip>
        </div>
      </div>
    </BaseHeader>
  );
};


export default withSystemNotifications(Header);
